




































































































import { Component, Vue } from "vue-property-decorator";
import api from "@/api/index";
import {
  CustomFormType,
  TrainManagementCreateOrUpdateDto,
  TrainManagementDto,
} from "@/api/appService";
import CustomFormDetailView from "@/components/CustomForm/FormDetail/index.vue";
import moment from "moment";
import CustomFormDataTableDetail from "@/components/CustomForm/TableDetail/index.vue";

@Component({
  name: "WorkOrderDetail",
  components: {
    CustomFormDetailView,
    CustomFormDataTableDetail,
  },
})
export default class WorkOrderDetail extends Vue {
  detail: TrainManagementDto = { train: {} };
  loading = true;
  customFormId = 0;
  isFormReady = false;
  private id = 0;

  created() {
    this.id = parseInt(this.$route.params.id, 10);
    this.fetchData();
  }

  getTime(item: any) {
    return moment(item).format("HH:mm");
  }

  fetchData() {
    api.trainManagement
      .get({ id: this.id })
      .then((result: TrainManagementDto) => {
        this.detail = result;
        this.loading = false;
        this.fetchFormDetail();
      });
  }

  async fetchFormDetail() {
    const formId = await api.customFormService.getCustomFormId({
      hostType: CustomFormType.TrainManage,
      hostId: "302",
    });

    this.custogetmFormId = parseInt(formId, 10);
    this.isFormReady = true;
  }

  fetchFormData(): Promise<TrainManagementCreateOrUpdateDto> {
    return api.trainManagement.getForEdit({ id: this.id });
  }
}
